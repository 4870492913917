import React, { useEffect, useState } from "react";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import MessageIcon from "@mui/icons-material/Message";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ReportIcon from "@mui/icons-material/Report";

import { Link, NavLink } from "react-router-dom";

import "./Sidebar.css";

function Sidebar() {

  return (
    <div className="sidebar">
      <div className="sidbarWrapper">
        <div className="sidebarMenue">
          <h3 className="sidebarTitle">داشبورد</h3>
          <ul className="sidebarList">
            <li>
              <NavLink to="/" className="link sidebarListItem">
                <LineStyleIcon />
                خانه
              </NavLink>
            </li>
            <li>
              <NavLink to="/tickets" className="link sidebarListItem">
                <PermIdentityIcon />
                مدیریت تیکت‌ها
              </NavLink>
            </li>
          </ul>
        </div>
        {/* 
        <div className="sidebarMenue">
          <h3 className="sidebarTitle">منوی دسترسی</h3>
          <ul className="sidebarList">
            <Link to="/users" className="link">
              <li className="sidebarListItem">
                <PermIdentityIcon />
                کاربران
              </li>
            </Link>
            <Link to="/physician" className="link">
              <li className="sidebarListItem">
                <PermIdentityIcon />
                پزشکان
              </li>
            </Link>
            <Link to="/patient" className="link">
              <li className="sidebarListItem">
                <PermIdentityIcon />
                بیماران
              </li>
            </Link>
            <Link to="/Exercise" className="link">
              <li className="sidebarListItem">
                <StorefrontIcon />
                تمرینات
              </li>
            </Link>
            <Link to="/Education" className="link">
              <li className="sidebarListItem">
                <StorefrontIcon />
                آموزش ها
              </li>
            </Link>
          </ul>
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
