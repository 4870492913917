import { Add } from "@material-ui/icons";
import { Dialog, Grid, IconButton, Pagination, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import NewTicketModal from "../../Components/NewTicketModal/NewTicketModal";
import Loader from "../../Components/Loader/Loader";
import SingleTicketRow from "../../Components/SingleTicketRow/SingleTicketRow";
import { getLoggedInData, parseJwt } from "../../utils";
import ChatBox from "../../Components/ChatBox/ChatBox";

import "./Tickets.css";
import MuiRTL from "../../Components/MuiRTL/MuiRTL";
import axios from "axios";
import Swal from "sweetalert2";

export default function Tickets() {
  const [allPatientTickets, setAllPatientTickets] = useState();
  const [activeTicket, setActiveTicket] = useState();
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const [ticketsCountPage, setTicketsCountPage] = useState();
  const [isNewTicketBoxOpen, setIsNewTicketBoxOpen] = useState(false);
  const [ticketsCurrentPage, setTicketsCurrentPage] = useState(1);
  const [isTicketsActive, setIsTicketsActive] = useState(null);

  const [opponent, setopponent] = useState({
    userID: -1,
    fullname: "",
  });
  const [starterUser, setStarterUser] = useState({
    userID: -1,
    fullname: "",
  });

  const singleTicketRowProps = {
    setopponent,
    setStarterUser,
    setActiveTicket,
  };

  const chatBoxProps = {
    activeChat: activeTicket,
    starterUser,
    opponent,
  };

  useEffect(() => {
    if (isTicketsActive) {
      getTicketsByPatientFilter(1);
    }
  }, []);

  useEffect(() => {
    getLoggedInData();
  });

  useEffect(() => {
    if (isTicketsActive) {
      getTicketsByPatientFilter(ticketsCurrentPage);
    }
  }, [ticketsCurrentPage]);

  function getLoggedInData() {
    let jwt_token = "";
    const localStorageData = localStorage.getItem("user");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      jwt_token = parsedData.token;
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };

      axios
        .get(`https://api.bettermove.ir/api/v1/Patient/GetLoginnedData`, {
          headers,
        })
        .then((res) => {
          console.log("user data logged in res", res.data.ticketAllowed);

          setIsTicketsActive(res.data.ticketAllowed);
        });
    } else {
      jwt_token = "no";
    }
  }

  function getTicketsByPatientFilter(pageNumber) {
    const localStorageData = localStorage.getItem("user");
    setIsLoadingTickets(true);
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      const token = parsedData.token;
      const pageSize = 4;

      fetch(
        `https://api.bettermove.ir/api/v1/Ticket/${pageNumber},${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setAllPatientTickets(data);
          setIsLoadingTickets(false);
          setTicketsCountPage(Math.ceil(data.totalcount / pageSize));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  function closeChatBox() {
    setActiveTicket(null);
    getTicketsByPatientFilter(ticketsCurrentPage);
  }

  const handlePageChange = (event, page) => {
    setTicketsCurrentPage(page);
  };

  if (isLoadingTickets) return <Loader />;

  if (isTicketsActive === false)
    return (
      <p className="no-active-message">
        بخش ارسال تیکت از طرف پزشک شما غیرفعال شده است
      </p>
    );

  return (
    <div>
      <Grid
        container
        alignItems="stretch"
        style={{ padding: "2rem", gap: ".5rem" }}
      >
        <Grid item xs={12} sm={3} style={{ maxWidth: "unset" }}>
          <button
            onClick={() => setIsNewTicketBoxOpen(true)}
            className="client-add-ticket"
          >
            <IconButton>
              <Add fontSize="large" style={{ color: "white" }} />
            </IconButton>
            تیکت جدید
          </button>
        </Grid>
        <Grid item xs={12} sm={3} style={{ maxWidth: "unset" }}>
          <div className="tickets-count">
            <ConfirmationNumberOutlinedIcon />
            <div>
              <span className="ticket-count-title">همه تیکت ها</span>
              <span>{allPatientTickets?.totalcount} عدد</span>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="client-ticket-table-container">
        <table className="client-tickets-table">
          <thead>
            <tr>
              <th>فرستنده</th>
              <th>گیرنده</th>
              <th>موضوع</th>
              <th>تاریخ ایجاد</th>
              <th>تاریخ آپدیت</th>
              <th>وضعیت</th>
            </tr>
          </thead>
          {allPatientTickets && (
            <tbody>
              {allPatientTickets.ticketslist?.map((ticket) => (
                <SingleTicketRow
                  isFromClient
                  key={ticket.keyID}
                  {...ticket}
                  {...singleTicketRowProps}
                />
              ))}
            </tbody>
          )}
        </table>
      </div>

      <Dialog
        className="client-chat-box-dialog"
        open={activeTicket}
        onClose={closeChatBox}
      >
        <ChatBox {...chatBoxProps} />
      </Dialog>

      <MuiRTL>
        <Stack
          spacing={2}
          direction="rtl"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px auto",
            width: "fit-content",
          }}
        >
          <Pagination
            count={ticketsCountPage}
            color="primary"
            page={ticketsCurrentPage}
            onChange={handlePageChange}
          />
        </Stack>
      </MuiRTL>

      <NewTicketModal
        isNewTicketBoxOpen={isNewTicketBoxOpen}
        setIsNewTicketBoxOpen={setIsNewTicketBoxOpen}
        getAllTickets={() => getTicketsByPatientFilter(1)}
      />
    </div>
  );
}
