import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./Modal.css";

export default function Modal({ files, onClose, filesType, educationInfo }) {
  console.log(educationInfo, "modal info");
  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.className === "modal") {
        onClose();
      }
    });

    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);

  return createPortal(
    <div className="modal">
      <div className="modal-box">
        {filesType === "education" ? (
          <div className="education-modal-box">
            <div className="header">
              <h6>{educationInfo.title}</h6>
            </div>
            <div className="main-content">
              <DisplayImages item={educationInfo.mainPicturePath} />
              <div className="full-content">
                <p>{educationInfo.content}</p>
              </div>
            </div>
            <div className="files-container">
              {files?.map((file) => (
                <a
                  download={`${file.fileName}.${file.fileType}`}
                  href={file.filesAddress}
                >
                  {file.fileName}.{file.fileType}
                </a>
              ))}
            </div>
          </div>
        ) : (
          files.map((file) => (
            <div key={file.keyID} className="single-file">
              {file.isImage ? (
                <div className="img-container">
                  <img src={file.filesAddress} alt="" />
                </div>
              ) : (
                <video controls>
                  <source
                    src={file.filesAddress}
                    type={`video/${file.fileType}`}
                  />
                  Your browser does not support the video tag.
                </video>
              )}
              <h2>{file.fileTitle}</h2>
              <p>{file.fileDescription}</p>
            </div>
          ))
        )}

        <button onClick={() => onClose()}>بستن</button>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

function DisplayImages({ item }) {
  function getFormat(file) {
    return file.mainPicturePath?.split(".").pop();
  }

  return ["jpg", "jpeg", "png", "gif", "webp"].includes(getFormat(item)) ? (
    <img
      className="img-format"
      src={item.mainPicturePath}
      alt={`file ${item.title}`}
    />
  ) : ["mp4", "avi", "mkv", "wmv", "webm", "mpeg", "mpg"].includes(
      getFormat(item)
    ) ? (
    <embed src={item.mainPicturePath} width="140px" height="80px" />
  ) : getFormat(item) === "pdf" ? (
    <img src="images/images.jfif" alt={`file ${item.title}`} />
  ) : getFormat(item) === "xlsx" ? (
    <img src="images/excel logo.png" alt={`file ${item.title}`} />
  ) : (
    <img src="images/file logo.png" alt={`file ${item.title}`} />
  );
}
